import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Container } from '@mui/material';
import { TEST_ID as BROWSER_FEATURE_DETECTION, BrowserFeatureDetectionResultRowContainer } from '../../testModules/browserFeatureDetection';
import { TEST_ID as AUDIO_CAPTURE, AudioCaptureResultRowContainer } from '../../testModules/audioCapture';
import { TEST_ID as VIDEO_CAPTURE, VideoCaptureResultRowContainer } from '../../testModules/videoCapture';
import { TEST_ID as BROWSER_VERSION, BrowserVersionResultRowContainer } from '../../testModules/browserVersion';
import { TEST_ID as SCREEN_SIZE, ScreenSizeResultRowContainer } from '../../testModules/screenSize';
import ResultCategory from './ResultCategory';
import { TestStepStates } from '../../testModules/core';
import { useTranslation } from 'react-i18next';
import { TEST_ID as TWILIO_VIDEO, TwilioVideoResultRowContainer } from '../../testModules/twilioVideo';
import { TEST_ID as CHIME_VIDEO, ChimeVideoResultRowContainer } from '../../testModules/chimeVideo';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

/** @module components:automatedTests:ResultsTable */

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 16
    }
}));

/**
 * Component to show the details of a full test run. Contains several ResultCategory components.
 */
const ResultsTable = ({ results, testsRunning }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const awsChimeEnabled = useFeatureIsOn('aws-chime');

    const computeStatus = (testIds) => {
        let statuses = testIds.map(id => results[id]?.status ?? TestStepStates.Disabled);

        statuses = statuses.filter(e => e !== TestStepStates.Disabled);
        
        if (statuses.length === 0) {
            return TestStepStates.Disabled;
        }
        
        if (statuses.some(e => e === TestStepStates.Failed)) {
            return TestStepStates.Failed;
        } else if (statuses.some(e => e === TestStepStates.Error)) {
            return TestStepStates.Error;
        } else if (statuses.some(e => e === TestStepStates.Warning)) {
            return TestStepStates.Warning;
        } else if (statuses.some(e => e === TestStepStates.Running)) {
            return TestStepStates.Running;
        } else if (statuses.some(e => e === TestStepStates.Waiting)) {
            return TestStepStates.Waiting;
        } else if (statuses.every(e => e === TestStepStates.Passed)) {
            return TestStepStates.Passed;
        } else {
            console.error('Returning error for: ',statuses);
            return TestStepStates.Error;
        }
    };
    
    
    return (<Container maxWidth="sm" className={classes.root}>
        <ResultCategory
            title={t('automaticTests.results.software.title')}
            pendingText={t('automaticTests.results.software.subtitle')}
            errorText={t('automaticTests.results.software.errorNote')}
            successText={t('automaticTests.testSectionPassed')}
            status={computeStatus([BROWSER_VERSION, BROWSER_FEATURE_DETECTION])}
            autoExpand={!testsRunning}
        >
            <BrowserVersionResultRowContainer />
            <BrowserFeatureDetectionResultRowContainer />
        </ResultCategory>
        <ResultCategory
            title={t('automaticTests.results.hardware.title')}
            pendingText={t('automaticTests.results.hardware.subtitle')}
            errorText={t('automaticTests.results.hardware.errorNote')}
            successText={t('automaticTests.testSectionPassed')}
            status={computeStatus([AUDIO_CAPTURE, VIDEO_CAPTURE, SCREEN_SIZE])}
            autoExpand={!testsRunning}
        >
            <AudioCaptureResultRowContainer />
            <VideoCaptureResultRowContainer />
            <ScreenSizeResultRowContainer />
        </ResultCategory>
        <ResultCategory
            title={t('automaticTests.results.network.title')}
            pendingText={t('automaticTests.results.network.subtitle')}
            errorText={t('automaticTests.results.network.errorNote')}
            successText={t('automaticTests.testSectionPassed')}
            status={computeStatus([awsChimeEnabled ? CHIME_VIDEO : TWILIO_VIDEO])}
            autoExpand={!testsRunning}
        >
            { awsChimeEnabled ? <ChimeVideoResultRowContainer /> : <TwilioVideoResultRowContainer />}
        </ResultCategory>
    </Container>);
};

export default ResultsTable;
