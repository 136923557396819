import React, { useEffect } from 'react';
import { Paper, Container, Card, Collapse } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import * as actions from '../../store/actionsTypes';
import { RecordingStates } from '../../store/reducers/videoTestReducer';
import PanelControls from './PanelControls';
import PanelContent from './PanelContent';
import UploadProgressPanel from './UploadProgressPanel';
import PropTypes from 'prop-types';

/** @module components:videoRecordingTest:VideoTestPanel */

const useStyles = makeStyles({
    recordingPanelContainer: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    videoPlayback: {
        margin: 32,
        overflow: 'hidden',
        width: 400,
        height: 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        maxWidth: '100%',
        background: '#222',
        '& > *, & video': {
            maxWidth: 400,
            maxHeight: 300
        }
    }
});


/**
 * Core UI for the video recording test based on Twilio's remote APIs.
 * @param {Object} props
 * @param {function} props.createTracks Callback to initialize the tracks. Bound by Redux - fires a
 *                   VIDEO_RECORDING_START_RECORDING action.
 * @param {function} props.startRecording Callback to start the recording process. Bound by Redux - fires a
 *                                        VIDEO_RECORDING_CREATE_TRACKS action.
 * @param {TwilioVideo:Track} props.videoTrack Video track to be rendered.
 * @param {MediaStreamTrack | MediaStream} props.videoStream Video stream track to be used in AwsChime recording
 * @param {boolean} props.initialized Flag to define whether the video feed has finished initializing.
 */
function VideoTestPanel ({ createTracks, startRecording, videoTrack, status, recordingUri, reset, saveRecording, saveProgress, recordProgress, videoStream }) {
    const classes = useStyles();
    const [playing, setPlaying] = React.useState(false);
    
    useEffect(() => {
        if (status === RecordingStates.INITIALIZING)
            createTracks();
    }, [status, createTracks]);

    const saving = status === RecordingStates.SAVING;
    const saveComplete = status === RecordingStates.SAVE_COMPLETE;
    const saveError = status === RecordingStates.SAVE_ERROR;

    const uploading = saving || saveComplete || saveError;
    
    return <Container maxWidth="sm">
        <Collapse in={!uploading}>
            <Paper className={classes.recordingPanelContainer}>
                <Card className={classes.videoPlayback} elevation={3}>
                    <PanelContent status={status} videoTrack={videoTrack} recordingUri={recordingUri} setPlaying={setPlaying} playing={playing} mediaStreamTrack={videoStream} />
                </Card>
                <PanelControls status={status} startRecording={startRecording} reset={reset} saveRecording={saveRecording} setPlaying={setPlaying} playing={playing} recordProgress={recordProgress} />
            </Paper>
        </Collapse>
        <Collapse in={uploading}>
            <UploadProgressPanel status={status} saveProgress={saveProgress} />
        </Collapse>
    </Container>;
}

VideoTestPanel.propTypes = {
    createTracks: PropTypes.func,
    startRecording: PropTypes.func,
    videoTrack: PropTypes.object,
    status: PropTypes.string,
    recordingUri: PropTypes.string,
    reset: PropTypes.func,
    saveRecording: PropTypes.func,
    saveProgress: PropTypes.func,
    recordProgress: PropTypes.func,
    videoStream: PropTypes.object
};

const mapStateToProps = (state) => {
    return {
        audioTrack: state.videoTestReducer.audioTrack,
        videoTrack: state.videoTestReducer.videoTrack,
        audioStream: state.videoTestReducer.audioStream,
        videoStream: state.videoTestReducer.videoStream,
        status: state.videoTestReducer.status,
        recordingUri: state.videoTestReducer.recordingUri,
        recordProgress: state.videoTestReducer.recordProgress,
        saveProgress: state.videoTestReducer.progress
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        startRecording: () => dispatch({type: actions.VIDEO_RECORDING_START_RECORDING}),
        createTracks: () => dispatch({type: actions.VIDEO_RECORDING_CREATE_TRACKS}),
        reset: () => dispatch({type: actions.VIDEO_RECORDING_TRACKS_RESET}),
        saveRecording: () => dispatch({type: actions.VIDEO_RECORDING_SAVE_RECORDING})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoTestPanel);