import React from 'react';
import { call, put, select } from 'redux-saga/effects';
import { TestStepStates, buildMapStateToProps, sleep, exceptionToObject } from './core';
import { testStatusUpdate } from '../store/actions';
import { connect } from 'react-redux';
import ResultRow from '../components/automatedTests/ResultRow';
import { useTranslation } from 'react-i18next';
import { VideoError, VideoFailed, VideoWarning } from '../components/automatedTests/resultsSummaries';
import PropTypes from 'prop-types';

import { DefaultMeetingSession, MeetingSessionConfiguration, ConsoleLogger, LogLevel, DefaultDeviceController } from 'amazon-chime-sdk-js';

export const TEST_ID = 'ChimeConnectivity';

/**
 * Connectivity test for Chime Video. Makes a connection to a meeting and pulls bandwidth stats for the resulting
 * connection.
 * @module testModules:chimeVideo
 */

const getVideoStats = (data, metric) => {
    if (!data || typeof data !== 'object') return null;

    const outerKey = Object.keys(data)[0];
    if (!outerKey || !data[outerKey]) return null;

    const innerKey = Object.keys(data[outerKey])[0];
    if (!innerKey || !data[outerKey][innerKey]) return null;

    return data[outerKey][innerKey][metric] ?? null;
};

class MetricsObserver {
    constructor(updateMetrics) {
        this.updateMetrics = updateMetrics;
    }

    metricsDidReceive(clientMetricReport) {
        const videoMetrics = clientMetricReport.getObservableVideoMetrics();
        this.updateMetrics({
            stats_sendBandwidth: getVideoStats(videoMetrics, 'videoUpstreamBitrate'),
            stats_sendLatency: getVideoStats(videoMetrics, 'videoUpstreamRoundTripTimeMs'),
            stats_sendJitter: getVideoStats(videoMetrics, 'videoUpstreamJitterMs'),
            stats_recvBandwidth: getVideoStats(videoMetrics, 'videoDownstreamBitrate'),
            stats_recvLatency: getVideoStats(videoMetrics, 'videoDownstreamDelayMs'),
        });
    }
}


const selectMeetingInfo = (store) => {
    return {
        meeting: store.configReducer.chime.meeting, 
        sendAttendee: store.configReducer.chime.attendeeSend,
        recvAttendee: store.configReducer.chime.attendeeRecv
    };
};

const connectToChime = async ({ meeting, attendee, updateMetrics }) => {
    const logger = new ConsoleLogger('ChimeLogger', LogLevel.ERROR);
    const configuration = new MeetingSessionConfiguration(meeting, attendee);
    const deviceController = new DefaultDeviceController(logger);
    const meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);

    const audioVideo = meetingSession.audioVideo;
    audioVideo.start();
    audioVideo.startLocalVideoTile(); // Ensure local video starts

    const videoDevices = await audioVideo.listVideoInputDevices();
    if (videoDevices.length > 0) {
        await deviceController.startVideoInput(videoDevices[0].deviceId);
    }

    audioVideo.addObserver(new MetricsObserver(updateMetrics));

    return meetingSession;
};

export function *runTest () {
    yield put (testStatusUpdate(TEST_ID, TestStepStates.Running, null));
    const { meeting, sendAttendee, recvAttendee } = yield select(selectMeetingInfo);
    let status = TestStepStates.Failed;

    const result = {
        exception: null,
        state: 'not done',
        stats_sendBandwidth: null,
        stats_sendLatency: null,
        stats_sendJitter: null,
        stats_recvBandwidth: null,
        stats_recvLatency: null
    };

    const updateMetrics = (metrics) => {
        Object.keys(metrics).forEach((key) => {
            if (result[key] === null) result[key] = metrics[key];
        });
    };

    try {
        const session1 = yield call(connectToChime, { meeting, attendee: sendAttendee, updateMetrics });
        const session2 = yield call(connectToChime, { meeting, attendee: recvAttendee, updateMetrics });

        // Wait to ensure both participants exchange data
        yield call(sleep, 5000);

        const MAX_ITERATIONS = 50;
        for (let i = 0; i < MAX_ITERATIONS; i++) {
            
            if (result.stats_recvBandwidth
                && result.stats_recvLatency
                && result.stats_sendBandwidth
                && result.stats_sendLatency
                && result.stats_sendJitter) {
                break;
            }

            const progressPercentage = (90 * i) / MAX_ITERATIONS;
            yield put (testStatusUpdate(TEST_ID, TestStepStates.Running, null, progressPercentage));
            yield call(sleep, 500);
        }

        yield put (testStatusUpdate(TEST_ID, TestStepStates.Running, null, 95));
        
        session1.audioVideo.stop();
        session2.audioVideo.stop();

        const { stats_recvBandwidth, stats_recvLatency, stats_sendBandwidth, stats_sendLatency, stats_sendJitter } = result;

        if (!stats_recvBandwidth || !stats_recvLatency || !stats_sendBandwidth || !stats_sendLatency || !stats_sendJitter) {
            status = TestStepStates.Error;
            result.exception = 'Failed to execute test - timed out.';
        } else if (stats_recvBandwidth < 5000 || stats_sendBandwidth < 5000 || stats_sendLatency > 400) {
            status = TestStepStates.Failed;
        } else if (stats_recvBandwidth < 15000 || stats_sendBandwidth < 15000 || stats_sendLatency > 400) {
            status = TestStepStates.Warning;
        } else {
            status = TestStepStates.Passed;
        }

        result.state = 'completed';
    } catch (e) {
        result.exception = exceptionToObject(e).name;
        console.error(result, e);
        status = TestStepStates.Error;
        yield call(sleep, 600); // For looks
    }
       
    yield put(testStatusUpdate(TEST_ID, status, result));
}

const ChimeVideoResultRow = ({status, progressPercentage}) => {
    const { t } = useTranslation();
    let error = '';

    switch (status) {
        case TestStepStates.Error:
            error = <VideoError />;
            break;
        case TestStepStates.Failed:
            error = <VideoFailed />;
            break;
        case TestStepStates.Warning:
            error = <VideoWarning />;
            break;
        default:
            break;
    }

    return <ResultRow 
        status={status} 
        title={t('automaticTests.results.twilioVideo.title')} 
        subtitle={t('automaticTests.results.twilioVideo.subtitle')} 
        errorMessage={error} 
        progressPercentage={progressPercentage} 
    />;
};

ChimeVideoResultRow.propTypes = {
    status: PropTypes.string,
    progressPercentage: PropTypes.number
};

const mapStateToProps = buildMapStateToProps(TEST_ID);

export const ChimeVideoResultRowContainer = connect(mapStateToProps)(ChimeVideoResultRow);
