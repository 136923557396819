import React from 'react';
import { Typography, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation, Trans } from 'react-i18next';

/**
 * Several useful reusable content blocks showing summaries of results.
 * Contains primarily specific failure cases (eg: incorrect web browser).
 * @module components:automatedTests:resultSummaries
 */

const useStyles = makeStyles({
    errorDetail: {
        marginBottom: 5
    }
});

/**
 * Explains that a user's web browser is unsupported.
 */
export const BadBrowser = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    return <>
        <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
            {t('automaticTests.results.badBrowser.line1')}
        </Typography>
        <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
            {t('automaticTests.results.badBrowser.line2', {context: props.productType})}
        </Typography>
        <Typography variant="caption" color="textSecondary" className={classes.errorDetail}>
            {t('automaticTests.results.badBrowser.line3')}
        </Typography>
    </>;
};

/**
 * Explains that the user's Operating System is unsupported.
 */
export const BadOS = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    return <>
        <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
            {t('automaticTests.results.badOs.line1')}
        </Typography>
        <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
            {t('automaticTests.results.badOs.line2')}
        </Typography>
        <Typography variant="caption" color="textSecondary" className={classes.errorDetail}>
            {t('automaticTests.results.badOs.line3')}
        </Typography>
    </>;
};

/**
 * Explains that the user is using a mobile device (phone), which is not allowed for this session.
 */
export const DeviceIsMobile = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    return <>
        <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
            {t('automaticTests.results.deviceIsMobile.line1')}
        </Typography>
        <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
            {t('automaticTests.results.deviceIsMobile.line2')}
        </Typography>
        <Typography variant="caption" color="textSecondary" className={classes.errorDetail}>
            {t('automaticTests.results.deviceIsMobile.line3')}
        </Typography>
    </>;
};

/**
 * Explains that certain expected features are disabled in the user's browser.
 */
export const MissingFeatures = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return <>
        <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
            {t('automaticTests.results.missingFeatures.line1')}
        </Typography>
        <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
            {t('automaticTests.results.missingFeatures.line2')}
        </Typography>
        <Typography variant="caption" color="textSecondary" className={classes.errorDetail}>
            {t('automaticTests.results.missingFeatures.line3')}
        </Typography>
    </>;
};

/**
 * Explains that the user's screen is too small.
 */
export const ScreenSizeFail = ({ minWidth, minHeight }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    return <>
        <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
            {t('automaticTests.results.badScreenSize.errorLine1')}
        </Typography>
        <Typography variant="caption" color="textSecondary" className={classes.errorDetail}>
            {t('automaticTests.results.badScreenSize.errorLine2', { minWidth, minHeight })}
        </Typography>
    </>;
};

/**
 * Explains that the user's screen is too small.
 */
export const ScreenSizeWarning = ({ minWidth, minHeight }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    return <>
        <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
            {t('automaticTests.results.badScreenSize.warningLine1')}
        </Typography>
        <Typography variant="caption" color="textSecondary" className={classes.errorDetail}>
            {t('automaticTests.results.badScreenSize.warningLine2', { minWidth, minHeight })}
        </Typography>
    </>;
};

/**
 * Explains that there was a problem running the A/V connectivity test.
 */
export const VideoError = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    return <>
        <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
            {t('automaticTests.results.twilioVideo.errorTitle')}
        </Typography>
        <Typography variant="caption" color="textSecondary" className={classes.errorDetail}>
            {t('automaticTests.results.twilioVideo.errorBody')}
        </Typography>
    </>;
};

/**
 * Explains that the user's internet connection is too slow/intermittent to run a session.
 */
export const VideoFailed = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    return <>
        <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
            {t('automaticTests.results.twilioVideo.failedTitle')}
        </Typography>
        <Typography variant="caption" color="textSecondary" className={classes.errorDetail}>
            {t('automaticTests.results.twilioVideo.failedBody')}
        </Typography>
    </>;
};

/**
 * Explains that a user's internet connection is not ideal - a bit too slow or intermittent.
 */
export const VideoWarning = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    
    return <>
        <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
            {t('automaticTests.results.twilioVideo.warningTitle')}
        </Typography>
        <Typography variant="caption" color="textSecondary" className={classes.errorDetail}>
            {t('automaticTests.results.twilioVideo.warningBody')}
        </Typography>
    </>;
};

/**
 * Types of device - used to lookup translation strings for missing devices.
 * @enum {string}
 */
const BadDeviceTypes = {
    WEBCAM: 'webcam',
    MICROPHONE: 'microphone'
};

/**
 * Supported browser types - used to switch help messages for suitable instructions.
 * @enum {string}
 */
const SupportedBrowser = {
    Chrome: 'chrome',
    Firefox: 'firefox',
    Safari: 'safari'
};

/**
 * Explains that there's been a problem accessing a user's media devices (webcam/microphone).
 * @param {Object} props React props
 * @param {BadDeviceTypes} props.deviceType Enum value for which type of devices this is an error for.
 * @param {boolean} props.isMissing Flag to indicate that there is no suitable device on the user's system.
 * @param {boolean} props.accessDenied Flag to indicate that the application was denied access when attempting to use
 *                                     the media device.
 * @param {SupportedBrowser} props.browser String containing the name of a supported browser. Must be a value from
 *                                         SupportedBrowser.
 */
const BadDevice = (props) => {
    const deviceType = props.deviceType;
    const isMissing = props.isMissing;
    const accessDenied = props.accessDenied;
    const browser = props.browser;

    const classes = useStyles();
    const { t } = useTranslation();

    let deviceTypeString = '';
    switch (deviceType) {
        case BadDeviceTypes.WEBCAM:
            deviceTypeString = t('automaticTests.results.badDevice.deviceTypes.webcam');
            break;
        case BadDeviceTypes.MICROPHONE:
            deviceTypeString = t('automaticTests.results.badDevice.deviceTypes.microphone');
            break;
        default:
            throw new Error('Invalid device type');
    }
    
    return <>
        <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
            {t('automaticTests.results.badDevice.line1', {deviceType: deviceTypeString})}
        </Typography>
        { isMissing ? 
            <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
                {t('automaticTests.results.badDevice.missing.line1', {deviceType: deviceTypeString})}
            </Typography>
            : null }
        { accessDenied ? 
            <>
                <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
                    {t('automaticTests.results.badDevice.accessDenied.line1', {deviceType: deviceTypeString})}
                </Typography>
                { browser === SupportedBrowser.Chrome ?
                    <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
                        <Trans i18nKey="automaticTests.results.badDevice.accessDenied.line2.chrome">
                            To enable your {{deviceType}}, check <Link color="secondary" href="https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&amp;hl=en">link</Link>.
                        </Trans>
                    </Typography>
                    : null}
                { browser === SupportedBrowser.Firefox ?
                    <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
                        <Trans i18nKey="automaticTests.results.badDevice.accessDenied.line2.firefox">
                            To enable your {{deviceType}}, check <Link color="secondary" href="https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions">link</Link>
                        </Trans>
                    </Typography>
                    : null}
                { browser === SupportedBrowser.Safari ?
                    <Typography variant="caption" color="textSecondary" paragraph className={classes.errorDetail}>
                        <Trans i18nKey="automaticTests.results.badDevice.accessDenied.line2.safari">
                            To enable your {{deviceType}}, check <Link color="secondary" href="https://support.apple.com/en-gb/guide/safari/ibrwe2159f50/mac">link</Link>
                        </Trans>
                    </Typography>
                    : null}
            </>
            : null }
        <Typography variant="caption" color="textSecondary" className={classes.errorDetail}>
            {t('automaticTests.results.badDevice.line2', {deviceType: deviceTypeString})}
        </Typography>
    </>;
};

export const BadWebcam = (props) => {
    return <BadDevice deviceType={BadDeviceTypes.WEBCAM} {...props} />;
};

export const BadMicrophone = (props) => {
    return <BadDevice deviceType={BadDeviceTypes.MICROPHONE} {...props} />;
};
