import * as actions from '../actionsTypes';

const initialState = {
    loaded: false,
    loadFailed: false,
    loadError: false,
    backendRoot: null,
    productType: null,
    testsToRun: []
};

const configReducer = (state, action) => { 
    if (typeof state === 'undefined') {
        return initialState;
    }
    switch (action.type) {
        case actions.AUTHENTICATION_COMPLETE_SUCCESS: {
            return {
                ...state,
                loaded: true,
                testsToRun: action.payload.testsToRun,
                backendRoot: action.payload.backendRoot,
                runRecordingTest: action.payload.runRecordingTest,
                recordingSystem: action.payload.recordingSystem,
                productType: action.payload.productType,
                chime: action.payload.chime
            };
        }
        case actions.AUTHENTICATION_COMPLETE_FAILED: {
            return {
                ...state,
                loadFailed: true
            };
        }
        case actions.AUTHENTICATION_COMPLETE_ERROR: {
            return {
                ...state,
                loadError: true
            };
        }
        default: {
            return state;
        }
    }
};

export default configReducer;
