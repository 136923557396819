import { call, put, select } from 'redux-saga/effects';
import * as actions from '../store/actionsTypes';
import * as browserFeatureDetection from '../testModules/browserFeatureDetection';
import * as audioCapture from '../testModules/audioCapture';
import * as videoCapture from '../testModules/videoCapture';
import * as browserVersion from '../testModules/browserVersion';
import * as screenSize from '../testModules/screenSize';
import * as twilioVideo from '../testModules/twilioVideo';
import * as chimeVideo from '../testModules/chimeVideo';
import { AwsChimeSystemChecksDirection, LoginType } from '../utils/enums';
import { API_BASE_URI } from '../Config';
import { growthbook } from '../utils/growthbookContext';

/** @module sagas:authSaga */
/**
 * Redux selector - gets the current auth token from the store.
 * @private
 * @param {} state current state
 */
export const getToken = (state) => {
    return state.resultSubmitReducer.token;
};

const FORBIDDEN_403 = 403;

const SUPPORTED_OS = ['Windows 10', 'Windows 8.1', 'Windows 8', 'Windows 7', 'Mac OS', 'iOS', 'Android OS'];
const SUPPORTED_BROWSERS = {
    windows: { chrome: '>=76', firefox: '>=71', edge: '>=44' },
    macos: { safari: '>=11', chrome: '>=76', firefox: '>=71' },
    android: { chrome: '>=76' },
    ios: { safari: '>=11', chrome: '>=76' }
};

/**
 * URI used for auth.
 * @private
 */
export const AUTH_URI = `${API_BASE_URI}api/systemtest/auth`;

/**
 * Saga that controls the authentication process. Includes logic to retrieve set up test configurations based on the
 * type of session returned during the authentication process.
 * @generator
 * @yields {AUTHENTICATION_COMPLETE_FAILED} Yielded when the authentication failed, due to incorrect credentials.
 * @yields {AUTHENTICATION_COMPLETE_ERROR} Yielded when authentication failed due to an error.
 * @yields {AUTHENTICATION_COMPLETE_SUCCESS} Yielded when authentication succeeded.
 */
export function *runAuthentication() {
    let vlqrAuthResponse;
    let vlqrResponseBody;
    let fgGetChimeSendInfoResponseBody;
    let fgGetChimeRecvInfoResponseBody;
    let awsChimeEnabled;

    const token = yield select(getToken);

    try {
        vlqrAuthResponse = yield call(fetch, `${AUTH_URI}/${token}`);

        if (!vlqrAuthResponse.ok) {
            // If we've got a failed status...
            if (vlqrAuthResponse.status === FORBIDDEN_403) {
                // ... handle an incorrect token gracefully...
                yield put({ type: actions.AUTHENTICATION_COMPLETE_FAILED, error: 'VLQR server rejected token' });
            } else {
                // ... or bail out if something's broken
                yield put({ type: actions.AUTHENTICATION_COMPLETE_ERROR, error: 'VLQR server returned an error status', status: vlqrAuthResponse.status });
            }
            // And then stop trying to run
            return;
        }
        vlqrResponseBody = yield call([vlqrAuthResponse, vlqrAuthResponse.json]);
    } catch (error) {
        yield put({ type: actions.AUTHENTICATION_COMPLETE_ERROR, error });
        return;
    }

    const productType = vlqrResponseBody.ProductType;
    let mobileAllowed = false;

    try {
        const fgBackendRoot = vlqrResponseBody.ServerBaseUri;
        const fgAuthResponse = yield call(fetch, `${fgBackendRoot}api/Auth/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: token,
                loginType: LoginType.SystemTest
            })
        });

        if (!fgAuthResponse.ok) {
            // If we've got a failed status...
            if (fgAuthResponse.status === FORBIDDEN_403) {
                // ... handle an incorrect token gracefully...
                yield put({ type: actions.AUTHENTICATION_COMPLETE_FAILED, error: 'FG Backend server rejected token' });
            } else {
                // ... or bail out if something's broken
                yield put({ type: actions.AUTHENTICATION_COMPLETE_ERROR, error: 'FG Backend server returned an error status', status: fgAuthResponse.status });
            }
            // And then stop trying to run
            return;
        }

        const fgResponseBody = yield call([fgAuthResponse, fgAuthResponse.json]);
        mobileAllowed = fgResponseBody.focusGroup.focusGroupSettings.allowMobileUsersToEnter;

        // set growthbook attributes to determine which set of tests to run later on
        growthbook?.setAttributes({
            ...fgResponseBody?.featureFlagAttributes
        });
        
        awsChimeEnabled = growthbook.isOn('aws-chime');

        if (awsChimeEnabled) {
            // only attempt if fgAuthResponse is ok
            if (fgAuthResponse.ok) {
                // retrieve send attendee for chimeVideo tests
                try {
                    const fgGetChimeInfoResponse = yield call(
                        fetch, 
                        `${fgBackendRoot}api/AwsChime/token/systemTest/?token=${token}&loginType=${LoginType.SystemTest}&direction=${AwsChimeSystemChecksDirection.Send}`,
                        {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                        });
            
                    fgGetChimeSendInfoResponseBody = yield call([fgGetChimeInfoResponse, fgGetChimeInfoResponse.json]);
                } catch (error) { 
                    yield put({ type: actions.AUTHENTICATION_COMPLETE_ERROR, error });
                    return;
                }

                // retrieve receive attendee for chimeVideo tests
                try {
                    const fgGetChimeInfoResponse = yield call(
                        fetch, 
                        `${fgBackendRoot}api/AwsChime/token/systemTest/?token=${token}&loginType=${LoginType.SystemTest}&direction=${AwsChimeSystemChecksDirection.Recv}`, 
                        {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                        });
            
                    fgGetChimeRecvInfoResponseBody = yield call([fgGetChimeInfoResponse, fgGetChimeInfoResponse.json]);
                } catch (error) { 
                    yield put({ type: actions.AUTHENTICATION_COMPLETE_ERROR, error });
                    return;
                }
            } 
        }
 
    } catch (error) {
        yield put({ type: actions.AUTHENTICATION_COMPLETE_ERROR, error });
        return;
    }

    // Payload - represents any data required to run the test suite.
    let payload = {
        // An array of TEST_IDs indicating which tests should be run.
        testsToRun: [
            browserFeatureDetection.TEST_ID
        ],

        // Root URL for the backend we're working with. If null, connectivity
        // tests that work with our backend will be failed with a warning.
        backendRoot: vlqrResponseBody.ServerBaseUri,

        // Include product type - allows text to be swapped based on product type.
        productType,

        // If the AWS Chime Growthbook flag is off use the existing recordingSystem 
        // object and add to the payload
        ...(!awsChimeEnabled && {
            recordingSystem: {
                type: 'TWILIO',
                sendApiKey: vlqrResponseBody.TwilioSendAccessToken,
                receiveApiKey: vlqrResponseBody.TwilioReceiveAccessToken,
                roomName: vlqrResponseBody.TwilioRoomName
            },
        }),
        // If the AWS Chime Growthbook flag is on use the chime data structure
        // object and add to the payload
        ...(awsChimeEnabled && {
            chime: {
                meeting: fgGetChimeSendInfoResponseBody.Meeting,
                attendeeSend: fgGetChimeSendInfoResponseBody.Attendee,
                attendeeRecv: fgGetChimeRecvInfoResponseBody.Attendee,
                roomType: fgGetChimeSendInfoResponseBody.RoomType
            }
        })
    };

    switch (productType) {
        case 'InDepthInterviewV2':
        case 'FocusGroupV2': {
            payload = {
                ...payload,
                runRecordingTest: false,
                testsToRun: [
                    ...payload.testsToRun,
                    { type: browserVersion.TEST_ID,
                        supportedOperatingSystems: SUPPORTED_OS,
                        supportedBrowsers: SUPPORTED_BROWSERS,
                        mobileAllowed: mobileAllowed
                    },
                    { type: screenSize.TEST_ID,
                        defaultMinWidth: 1187,
                        defaultMinHeight: 600,
                        mobileMinWidth: 320,
                        mobileMinHeight: 320 }
                ]
            };
            break;
        }
        case 'InDepthInterviewV2Av':
        case 'FocusGroupV2Av': {
            payload = {
                ...payload,
                runRecordingTest: true,
                testsToRun: [
                    ...payload.testsToRun,
                    { type: browserVersion.TEST_ID,
                        supportedOperatingSystems: SUPPORTED_OS,
                        supportedBrowsers: SUPPORTED_BROWSERS,
                        mobileAllowed: mobileAllowed
                    },
                    audioCapture.TEST_ID,
                    videoCapture.TEST_ID,
                    { type: screenSize.TEST_ID,
                        defaultMinWidth: 768,
                        defaultMinHeight: 500,
                        mobileMinWidth: 320,
                        mobileMinHeight: 320 },
                    awsChimeEnabled ? chimeVideo.TEST_ID : twilioVideo.TEST_ID
                ],
            };
            break;
        }
        default:
            throw new Error('Unsupported session type');
    }

    yield put({
        type: actions.AUTHENTICATION_COMPLETE_SUCCESS,
        payload: payload
    });
}