import React, { useEffect } from 'react';
import { useRef } from 'react';
import PropTypes from 'prop-types';

/** @module components:videoRecordingTest:AwsChimeVideoTrack */

/**
 * Renders a suitable media element for the specified AWS Chime SDK audio or video track.
 * Handles attach/detach events and renders the correct type of element.
 * 
 * @param {Object} props NOTE: props not specified below are passed to the child media element.
 * @param {MediaStreamTrack | MediaStream} props.track - The AWS Chime SDK media track (audio or video).
 */
function AwsChimeVideoTrack ({track, ...props}) {
    const mediaElement = useRef();
    const kind = track?.kind;

    useEffect(() => {
        const element = mediaElement.current;
        if (!element) return;

        let stream = null;

        if (track instanceof MediaStream) {
            // If the track is already a MediaStream, use it directly
            stream = track;
        } else if (track instanceof MediaStreamTrack) {
            // Wrap a single track inside a MediaStream
            stream = new MediaStream([track]);
        } else {
            console.error('Invalid track received:', track);
        }

        if (stream) {
            element.srcObject = stream;
            element.play().catch(error => console.error('Playback error:', error));
        }

        return () => {
            if (element) {
                element.srcObject = null;
            }
        };
    }, [track]);

    if (!track) return null;

    switch (kind) {
        case 'video': {
            return <video playsInline muted={true} ref={mediaElement} {...props} />;
        }
        case 'audio': {
            return <audio ref={mediaElement} {...props} />;
        }
        default: {
            return null;
        }
    }
}

AwsChimeVideoTrack.propTypes = {
    track: PropTypes.object
};

export default AwsChimeVideoTrack;
