/**
 * A set of possible events that a login may represent.
 * @readonly
 * @enum {number}
 */
export const LoginType = {
    /** Event represents joining a session */
    SessionJoin: 0,
    /** Event representing the initial auth step for a sytem test */
    SystemTest: 1
};

export const AwsChimeSystemChecksDirection = {
    /** Used to create a 'Send' version of AWS Chime Attendee for loopback video tests */
    Send: 0,
    /** Used to create a 'Recv' version of AWS Chime Attendee for loopback video tests */
    Recv: 1
};
